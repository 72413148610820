import React, { useEffect } from 'react';

const siteId = '14c94bcb1f44ec5c5721f4fb2d38c9b3'; // Production Site ID
// const siteId = 'cb4fbe6a5f6caec1f1715b85fecd2d7f'; // Test Site ID: http://localhost:8000

const OWA_Analytics = () => {

  useEffect(() => {
    // Remove any existing owa-root div and _owa script from previous renders
    const existingOwaRoot = document.getElementById('owa-root');
    if (existingOwaRoot) {
      existingOwaRoot.remove();
    }

    const existingScript = document.querySelector('script[src*="owa.tracker.js"]');
    if (existingScript) {
      existingScript.remove();
    }

    // Add new owa-root div
    const owaRootDiv = document.createElement('div');
    owaRootDiv.id = 'owa-root';
    document.body.appendChild(owaRootDiv);

    // Add OWA tracking script after a delay
    const initializeOWA = () => {
      window.owa_baseUrl = 'https://analytics.techna.pk/';
      window.owa_cmds = window.owa_cmds || [];
      window.owa_cmds.push(['setSiteId', siteId]);
      window.owa_cmds.push(['trackPageView']);
      window.owa_cmds.push(['trackClicks']);

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = window.owa_baseUrl + 'modules/base/dist/owa.tracker.js';

      const firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(script, firstScript);
    };

    const timeoutId = setTimeout(initializeOWA, 1500);

    // Cleanup function to remove elements on component unmount
    return () => {
      clearTimeout(timeoutId);
      const owaRoot = document.getElementById('owa-root');
      if (owaRoot) {
        owaRoot.remove();
      }
      const script = document.querySelector('script[src*="owa.tracker.js"]');
      if (script) {
        script.remove();
      }
    };
  }, []);

  return null;
};

export default OWA_Analytics;
